
import { Vue, Component, Prop } from "vue-property-decorator";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import { v4 as uuidv4 } from "uuid";

@Component
export default class VFancyBox extends Vue {
  @Prop(String) readonly src!: string;
  @Prop(String) readonly itemId!: string;
  @Prop([String, Array, Object]) readonly contentCss!:
    | Record<string, any>
    | string[]
    | string;
  @Prop([String, Array, Object]) readonly css!:
    | Record<string, any>
    | string[]
    | string;
  @Prop({ type: String, default: "a" }) readonly tag!: string;
  @Prop({ type: Number, default: 500 }) readonly delay!: number;
  @Prop({ type: String, default: "inline" }) readonly type!: string;
  @Prop({ type: Object, default: () => ({}) }) readonly options!: Record<
    string,
    any
  >;

  onOpen(): void {
    this.$emit("opening");
    this.$_.delay(this.init, this.delay);
  }

  init(): void {
    Fancybox.show([{ src: this.sSrc, type: this.type }], this.options);
    // Fancybox.bind("[data-fancybox]", this.options);
  }

  get elId(): string {
    return this.itemId || uuidv4();
  }

  get sSrc(): string {
    return this.src || `#${this.elId}`;
  }
}
